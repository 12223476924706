import { render, staticRenderFns } from "./PatientsHistoryOfActions.vue?vue&type=template&id=5db4c874&scoped=true"
import script from "./PatientsHistoryOfActions.vue?vue&type=script&lang=js"
export * from "./PatientsHistoryOfActions.vue?vue&type=script&lang=js"
import style0 from "./PatientsHistoryOfActions.vue?vue&type=style&index=0&id=5db4c874&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db4c874",
  null
  
)

export default component.exports