<template>
  <div class="history">
    <div class="h3 mb-4">
      История деперсонализации и объединения
    </div>
    <div class="input-wrapper">
      <div class="group">
        <b-input-group class="bg-white rounded">
          <b-form-input
            v-model="patientId"
            type="number"
            style="width: 295px;"
            placeholder="введите ID учетной записи"
            @keyup.enter="fetchHistoryOfActions"
          />
        </b-input-group>

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          :disabled="!(patientId?.length === 7 || patientId?.length === 0)"
          @click="fetchHistoryOfActions"
        >
          <b-icon icon="search" />
        </b-button>
      </div>
      <span
        v-if="patientId && String(patientId)?.length !== 7"
        class="input-info-text"
      >Количество введённых символов должно быть равно 7</span>
    </div>

    <b-overlay
      v-if="!actions"
      :show="isLoading"
      variant="transparent"
      spinner-variant="primary"
      blur="1px"
      no-wrap
    />

    <div
      v-if="+allCount === 0"
      class="empty"
    >
      Ничего не найдено
    </div>

    <template v-else>
      <custom-scrollbar>
        <b-table
          v-if="actions"
          :fields="fields"
          :items="actions"
          :busy="isLoading"
          :small="true"
          :current-page="currentPage"
          class="bg-white"
          bordered
          hover
        >
          <template v-slot:cell(date)="row">
            <span>{{ createDateFormat(row) }}</span>
          </template>
        </b-table>
      </custom-scrollbar>
      <div
        class="pagination"
      >
        <b-pagination
          v-model="currentPage"
          :per-page="limit"
          :total-rows="allCount"
          style="padding-top: 10px"
          @input="onClickNextPage"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { format } from '@evd3v/date-fns';
import { personService } from '@/services';

const LIMIT = 25;

export default {
  name: 'PatientsHistoryOfActions',

  data() {
    return {
      isLoading: false,
      patientId: null,
      currentPage: 1,
      limit: LIMIT,
      fields: [
        {
          key: 'date',
          label: 'Дата',
          class: 'td-data',
        },
        {
          key: 'action',
          label: 'Информация',
        },
      ],
      actions: [],
      allCount: null,
    };
  },
  computed: {
    paginationParams() {
      return {
        id: this.patientId,
        take: this.limit,
        skip: this.offSet,
      };
    },
    offSet() {
      return (this.currentPage - 1) * this.limit;
    },
  },
  async created() {
    await this.fetchHistoryOfActions();
  },
  methods: {
    createDateFormat(row) {
      if (!row) return null;

      const date = new Date(row.value);
      const offset = 3 * 60 * 60 * 1000;
      const dateWithOffset = new Date(date.getTime() + offset);

      return format(dateWithOffset, 'dd.MM.yyyy HH:mm');
    },
    async fetchHistoryOfActions() {
      try {
        this.isLoading = true;
        ({ data: this.actions, xTotalCount: this.allCount } = await personService.getHistoryOfActions(this.paginationParams));
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    async onClickNextPage() {
      await this.fetchHistoryOfActions();
    },
  },
};
</script>
<style lang="scss" scoped>

::v-deep .td-data {
    width: 150px;
    vertical-align: middle;
    text-align: center;
  }
.history {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;

  .input-wrapper{
    position: relative;
    display: flex;
    margin-bottom: 15px;
    .group {
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .input-info-text{
      position: absolute;
      left: 0;
      bottom: -10px;
      font-size: 12px;
      color: $cell-dangerous;
    }
  }
  .pagination {
    margin-top: auto;
  }
  .empty {
   margin-top: 30px;
  }
}
</style>
